import styled from 'styled-components'
import { useState, useEffect } from 'react'
import VhsVideo from '../../UI/vhsVideo/VhsVideo'
import useTypingEffect from '../../UI/typingEffect/TypingEffect'

const StyledServices = styled.section`
    background-color: #121212;
    color: white;
    padding-bottom: 50px;
    padding-top: 50px;

    .videoEditing, .operator, .videoTapes {
        display: grid;
        grid-template-columns: 1fr 1fr; 
        align-items: end; 
        text-align: left;
        margin: 0 auto;
        max-width: 1000px;

        h1 {
            grid-column: 1 / 2;
            font-size: 3rem;
            width: fit-content;
            margin: 0;
        }

        h2 {
            font-size: 30px;
            grid-column: 2 / 3; 
            margin: 5px 0;
            transition: transform 0.3s ease;

            &:hover {
                color: #94d0ab;
                cursor: text;
                transform: scale(1.05);
            }
        }

        p {
            grid-column: 2 / 3; 
            font-size: 16px; 
            line-height: 1.6;
        }

        ul {
            grid-column: 2 / 3; 
            list-style-type: none; 
            padding: 0; 
            margin: 0 0 20px 0; 
        }

        li {
            font-size: 16px;
            line-height: 1.6;
            margin-bottom: 10px;
            position: relative;
            color: #ffffff;

            &:last-child {
            margin-bottom: 0;
            }
        }
    }

    .videoTapes {
    
    span {
        color: #158680;
        font-weight: 800;
        text-transform: uppercase;
        text-decoration: underline;

        &:after {
            content: '|';
            opacity: 1;
            animation: blink 1s step-end infinite;
        }
    
        &:hover {
            cursor: pointer;
            text-decoration: underline;
            color: #94d0ab;
        }
    }
}

.operatorProjects {
    color: #158680;
    font-weight: 600;
    cursor: pointer;
    margin-left: 2px;

    display: inline-flex; 
    align-items: center; 

    .bi-caret-down-fill {
      transition: transform 0.3s ease-in-out;
      transform: rotate(${props => props.showProjectsList ? '180deg' : '0deg'});
    }
}

.videoEditing h2, .operator h2, .videoTapes h2 {
    color: white; 
    transition: color 0.3s ease, transform 0.3s ease;
}

.scale-up-on-scroll h2 {
    color: #158680; 
    transform: scale(1.05);
}

@keyframes blink {
        50% {
            opacity: 0;
        }
    }

    @media (max-width: 768px) {
        .videoEditing, .operator, .videoTapes {
      padding: 20px;
      grid-template-columns: 1fr;

      h1, h2, p, ul {
        grid-column: 1 / -1; 
      }

      ul {
        margin-bottom: 0;
      }
    }

    .videoEditing > h1 {
        padding: 0 0 30px 0;
    }

    .scale-up-on-scroll h2 {
    color: #158680; 
    transform: scale(1);
    }

    .videoEditing > p {
        padding: 0;
    }

    .operator > p {
        padding: 0;
    }

    .videoTapes > p {
        padding: 0;
    }
}

@media (max-width: 480px) {
    .videoEditing, .operator, .videoTapes {
        padding: 20px;
        grid-template-columns: 1fr; 
    }

    h1 {
            grid-column: 1 / 2;
            font-size: 2.5rem;
            margin-left: 0;
            padding: 0 0 30px 10px;
        }
        h2 {
            grid-column: 1 / 2;
            font-size: 24px;
        }
        p {
            grid-column: 1 / 2;
            font-size: 14px;
            padding: 0 20px;
        }
}

@media (min-width: 1920px) {
        padding-bottom: 100px; 
        padding-top: 100px;

        .videoEditing, .operator, .videoTapes {
            max-width: 1600px; 
            grid-template-columns: 1fr 1.5fr; 
            gap: 40px; 
            position: relative;

            h1 {
                grid-column: 1;
                font-size: 5rem;
                transform: translateX(-100px);
            }
            h2 {
                grid-column: 2;
                font-size: 4rem;
                transform: translateX(100px);
            }
            p {
                font-size: 28px; 
                line-height: 1.8;
                margin-top: 0;
                transform: translateX(100px);
            }
        }
    }

    @media (min-width: 2560px) {
        .videoEditing, .operator, .videoTapes {
            h1 {
                grid-column: 1;
                font-size: 5rem;
                transform: translateX(-400px);
            }
            h2 {
                grid-column: 2;
                font-size: 4rem;
                transform: translateX(100px);
            }
            p {
                font-size: 28px; 
                line-height: 1.8;
                margin-top: 0;
                transform: translateX(100px);
            }
        }
    }

`

const StyledVideo = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    margin-top: 20px;
    padding-left: 30px;  
    padding-right: 30px;
  }

  video {
    width: 100%;
    height: auto;
  }
`;

const OperatorProjectsList = styled.ul`
  list-style-type: none;
  padding: 0;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;

  &.show {
    max-height: 500px;
    opacity: 1;
  }

  li {
    list-style-type: none;
    a {
      color: inherit; 
      text-decoration: none; 
      
      &:hover {
        color: #158680;
        text-decoration: underline; 
      }
    }
  }

  .bi-camera-reels-fill {
    margin-right: 10px;
  }

  @media (max-width: 768px) {
    li {
        a {
            text-decoration: underline;
        }
    }
  }
`;

const Services = () => {

    const [isInView, setIsInView] = useState({ videoEditing: false, operator: false, videoTapes: false });
    const [showProjectsList, setShowProjectsList] = useState(false);
    
    useEffect(() => {
        const handleScroll = () => {
            const videoEditingInView = isElementInView(document.querySelector('.videoEditing'));
            const operatorInView = isElementInView(document.querySelector('.operator'));
            const videoTapesInView = isElementInView(document.querySelector('.videoTapes'));
        
            setIsInView({ 
                videoEditing: videoEditingInView, 
                operator: operatorInView, 
                videoTapes: videoTapesInView 
            });
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll(); 
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    
    const isElementInView = (element) => {
        if (!element) return false;
        const rect = element.getBoundingClientRect();
        return rect.top < window.innerHeight && rect.bottom >= 0;
    };

    const [showModal, setShowModal] = useState(false);
    const toggleModal = () => setShowModal(!showModal);
    const toggleProjectsList = () => setShowProjectsList(!showProjectsList);

    const typingTexts = ["Revisit the echoes of past joys.", "Discover timeless memories.", "Experience digital nostalgia."];
    const typedText = useTypingEffect(typingTexts, 50, 1000);

    const projects = [
        { name: "LRT TV. Programme „Ieškome meno”; Programme „Dinastijos” (operator);", url: "https://www.lrt.lt/mediateka/irasas/2000310444" },
        { name: "Lietuvos ryto TV. Programme „Gyvenimas versle”;(operator, montage director);", url: "https://www.youtube.com/embed/x6as4EnXdZ4"},
        { name: "Lietuvos ryto TV. Programme „60+ Pirmi kartai” (operator, montage director);", url: "https://www.youtube.com/embed/aPmuld3fDpE"},
        { name: "Go3 TV. Programme „Visada pliuse” (operator, montage director);", url: "https://www.youtube.com/embed/adnqPBSRIbA" },
        { name: "Delfi TV. Programme „Kieno didesni” (operator, montage director);", url: "https://www.youtube.com/embed/yZlQFxRzghU" },
        { name: "Youtube. UBR Team channel operator and montage;", url: "https://www.youtube.com/@ubrteam795" },
        { name: "Youtube. Series of programmes about manors „Dvarų dėlionė;”", url: "https://www.youtube.com/embed/4gHUbfCmYTA" },
        { name: "Youtube. Series of programmes on agriculture;", url: "https://www.youtube.com/embed/4v1poa_u6UM" }
    ];

    return ( 
        <StyledServices id='services'>
            <div>
                <div className={`videoEditing ${isInView.videoEditing ? 'scale-up-on-scroll' : ''}`}>
                    <h1>Expertise</h1>
                    <h2>Video Editing</h2>
                    <p>As a professional video editor, I bring raw footage to life, crafting compelling narratives with expert precision. My approach involves meticulous cutting, dynamic sequencing, and vibrant color grading to ensure each project, regardless of its nature, captivates and engages its audience.</p>
                </div>
                <div className={`operator ${isInView.operator ? 'scale-up-on-scroll' : ''}`}>
                    <h2>Operator</h2>
                    <p>As an operator, I excel in efficiently managing and controlling machinery and systems. My focus is on ensuring smooth operations, maintaining safety standards, and optimizing performance to achieve the best results in every task I undertake. 
                        <span className='operatorProjects' onClick={toggleProjectsList}>
                        <strong> PROJECTS LIST</strong>
                        <i className={`bi bi-caret-down-fill ${showProjectsList ? 'rotate' : ''}`} style={{ 
                            transition: 'transform 0.3s ease-in-out',
                            transform: showProjectsList ? 'rotate(180deg)' : 'rotate(0deg)'
                        }}></i>
                        </span></p>
                        <OperatorProjectsList className={showProjectsList ? 'show' : ''}>
                            {projects.map((project, index) => (
                                <li key={index}><a href={project.url} target="_blank" rel="noopener noreferrer"><i className="bi bi-camera-reels-fill"></i>{project.name}</a></li>
                            ))}
                        </OperatorProjectsList>
                </div>
                <div className={`videoTapes ${isInView.videoTapes ? 'scale-up-on-scroll' : ''}`}>
                    <h2>Digitization of Video Tapes</h2>
                    <p>I'm thrilled to offer my new service that combines the digitization of video tapes with creative video editing. This dual service not only preserves your cherished memories by converting old tapes into digital formats but also transforms them into refreshed, modern videos. By reviving vintage footage and infusing it with new life, I ensure that your precious moments are not just safely archived but also reimagined and relived in a contemporary and dynamic way. <span onClick={toggleModal} aria-label="Tap to view video">{typedText}</span></p>
                    {showModal && <VhsVideo onClose={() => setShowModal(false)} />}
                </div>
            </div>
        </StyledServices>
     );
}
 
export default Services;