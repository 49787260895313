import { keyframes } from "styled-components";
import styled from "styled-components";
import VideoThumbnail from "../videoThumbnail/VideoThumbnail";

const StyledDiv = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
    grid-gap: 20px;
    padding: 20px;
    justify-content: center;

    .video-item {
        position: relative;
        overflow: hidden;
        box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        aspect-ratio: 16 / 9;
        background: #000;
        transition: transform 0.3s ease, box-shadow 0.3s ease;

        &:hover .video-info {
            opacity: 1;
            transform: translateY(0);
        }

        &:hover {
            transform: scale(1.03); 
            box-shadow: 0 6px 12px rgba(0,0,0,0.2); 
            cursor: pointer;

            @media (max-width: 600px) {
                transform: none;
                box-shadow: 0 4px 8px rgba(0,0,0,0.1);
            }
        }
    }

        .video-info {
            position: absolute;
            top: 0; 
            left: 0;
            right: 0;
            background: rgba(0, 0, 0, 0.7); 
            color: #fff;
            padding: 10px;
            transform: translateY(-100%); 
            transition: transform 0.3s ease, opacity 0.3s ease;
            opacity: 0;
            overflow: hidden;
            line-height: 150%;

            h3 {
                margin-top: 0;
                font-size: 1.2rem; 
            }

            p {
                font-size: 1rem; 
                margin-bottom: 0;
            }

            &:hover .video-info {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @media (max-width: 600px) {
        grid-template-columns: 1fr;

        .video-item {
            

            .video-info {
                display: none; 
            }
        }
    }
`

// const slideInFromLeft = 
//   from {
//     transform: translateX(-100%);
//     opacity: 0;
//   }
//   to {
//     transform: translateX(0);
//     opacity: 1;
//   }
// `;

// const slideOutToLeft = 
//   from {
//     transform: translateX(0);
//     opacity: 1;
//   }
//   to {
//     transform: translateX(-100%);
//     opacity: 0;
//   }
// `;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;


const VideoItemStyled = styled.div`
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  aspect-ratio: 16 / 9;
  background: #000;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  animation: ${props => props.isExiting ? fadeOut : fadeIn} ${props => props.isExiting ? '1s' : '2s'} ease-out forwards;
  animation-delay: ${props => props.$delay}s;

  &:hover .video-info {
    opacity: 1;
    transform: translateY(0);
  }

  &:hover {
    transform: scale(1.03); 
    box-shadow: 0 6px 12px rgba(0,0,0,0.2); 
    cursor: pointer;
  }

  @media (max-width: 600px) {
    &:hover {
      transform: none; 
      box-shadow: 0 4px 8px rgba(0,0,0,0.1); 
    }

    .video-info {
      display: none;
    }
  }
  `;


const VideoComponent = ({ videoPaths, onVideoClick, isExiting }) => {

    const handleClick = (video) => {
        onVideoClick(video.videoUrl);
    };

    return (
        <StyledDiv>
            {videoPaths.map((video, index) => (
              <VideoItemStyled key={video.id} delay={index * 0.2} onClick={() => handleClick(video)} isExiting={isExiting}>
                <VideoThumbnail thumbnailUrl={video.thumbnailUrl} />
                <div className="video-info">
                  <h3>{video.title}</h3>
                  <p>{video.description}</p>
                  <p>{new Date(video.datePublished).toLocaleDateString()}</p>
                </div>
              </VideoItemStyled>
            ))}
        </StyledDiv>
    );
};

export default VideoComponent;