import Typewriter from "./UI/typewriter/TypeWriter";
import video from "./images/burba.mp4"
import styled from 'styled-components';

const StyledHero = styled.section`
    padding: 50px 50px;
    transition: background-color 1.5s ease;

    .video_holder {
        max-width: 1000px; 
        width: 100%; 
        height: auto; 
        margin: 0 auto; 
        overflow: hidden; 
        display: flex; 
        align-items: center; 
        justify-content: center; 

        @media (min-width: 1920px) {
            max-width: 2000px;
        }
    }

    .video_holder video {
        width: 100%; 
        max-width: 1920px;
        height: auto; 
    }

    .heroPage >  h1 {
        font-size: 74px;
        text-align: center;
        text-transform: uppercase;
        transition: color 1s ease; 

        @media (min-width: 1920px) { 
            font-size: 100px; 
        }
    }

    .heroPage >  h1:nth-child(1) {
        margin-top: 30px;
        margin-bottom: 20px;
        cursor: pointer;
        transition: transform 1s ease;
    }

    .heroPage >  h1:nth-child(2) {
        margin: 0 auto;
        cursor: pointer;
        transition: transform 1s ease;
        width: fit-content;
    }

    .heroPage >  h1:nth-child(1):hover,
    .heroPage >  h1:nth-child(2):hover {
    transform: scale(1.2);
    }


    .scroll-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    cursor: pointer;

    &::after {
        content: '';
        display: block;
        width: 30px;
        height: 30px;
        border-bottom: 3px solid #000000; 
        border-right: 3px solid #000000; 
        transform: rotate(45deg); 
        margin: -10px;

        @media (min-width: 1920px) {
            width: 50px;
            height: 50px;
        }

        }

    }

    .tagline {
        font-size: 16px; 
        text-align: center;
        color: #333;
        margin-top: 20px; 
        font-style: italic; 

        @media (min-width: 1920px) {
            font-size: 30px;
        }
    }

    @media (max-width: 768px) {
        padding: 40px 0;
        .heroPage > h1 {
            font-size: 44px;
        }
    }

    @media (hover: none) and (pointer: coarse), (max-width: 768px) {
    .heroPage >  h1:nth-child(1):hover,
    .heroPage >  h1:nth-child(2):hover {
        transform: none;
    }
}

    @media (min-width: 1920px) {
        padding: 100px; 
        
        .heroPage > h1 {
            font-size: 160px;
        }
    }

`

const HeroPage = () => {

    const handleArrowClick = () => {
        // Logic to scroll down or navigate
        document.getElementById('profileSection').scrollIntoView({ behavior: 'smooth' });
    };

    const handleMouseEnter = () => {
        const heroSection = document.getElementById('heroVideo');
        heroSection.style.backgroundColor = '#000000'; 
        const firstH1 = heroSection.querySelector('.heroPage > h1:nth-child(1)');
        const secondH1 = heroSection.querySelector('.heroPage > h1:nth-child(2)');
        
        if (firstH1) {
            firstH1.style.color = 'white'; 
        }
        if (secondH1) {
            secondH1.style.color = '#333'; 
        }
    };

    const handleMouseLeave = () => {
        const heroSection = document.getElementById('heroVideo');
        heroSection.style.backgroundColor = '#FFFFFF'; 
        const firstH1 = heroSection.querySelector('.heroPage > h1:nth-child(1)');
        const secondH1 = heroSection.querySelector('.heroPage > h1:nth-child(2)');
        
        if (firstH1) {
            firstH1.style.color = 'black'; 
        }
        if (secondH1) {
            secondH1.style.color = 'black'; 
        }
    }
    return ( 
        <StyledHero id="heroVideo">
            <div className="logo_mask">
                <div className="video_holder">
                    <video width="1000" height="350" loop autoPlay muted>
                        <source src={video} type='video/mp4' />
                    </video>
                </div>
            </div>
            <div className="heroPage">
                <h1 onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}><Typewriter text="Video Editor" typingSpeed={75} /></h1>
                <h1><Typewriter text="Operator" typingSpeed={75} /></h1>
                <p className="tagline">Crafting Visual Stories That Resonate</p> 
                <div className="scroll-arrow" onClick={handleArrowClick}></div>
            </div>
        </StyledHero>
    );
}
 
export default HeroPage;