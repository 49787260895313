import styled from 'styled-components';

const ModalBackdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.893);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    .videoContainer {
        width: 100%; 
        max-width: 1100px;
        aspect-ratio: 16 / 9; 
    }

    .videoContainer iframe {
        width: 100%; 
        height: 100%; 
        border: none;
    }

    @media (max-width: 768px) {
        .videoContainer {
            width: 95%; 
        }
    }

    @media (min-width: 1920px) {
        .videoContainer {
        width: 80%; 
        max-width: none;
        aspect-ratio: 16 / 9; 
        }
    }
`;

const VideoModal = ({ videoUrl, onClose }) => {

    if (!videoUrl) return null;

    return (
        <ModalBackdrop onClick={onClose}>
            <div className="videoContainer">
                <iframe 
                    src={videoUrl}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                    onClick={e => e.stopPropagation()} 
                ></iframe>
            </div>
        </ModalBackdrop>
    );
};

export default VideoModal;