import styled, { keyframes } from "styled-components";
import { useState, useEffect } from "react";

const pixelate = keyframes`
  0% { filter: blur(5px); }
  100% { filter: blur(0px); }
`;

const pulse = keyframes`
  0% { 
    transform: translate(-50%, -50%) scale(1); 
  }
  50% { 
    transform: translate(-50%, -50%) scale(1.1); 
  }
  100% { 
    transform: translate(-50%, -50%) scale(1); 
  }
`;


const CameraIcon = styled.i`
  font-size: 50px;  
  color: #000000;   
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${({ progress }) => `rgb(${progress * 2.55}, ${progress * 2.55}, ${progress * 2.55})`};
  transition: color 1s linear;
  animation: ${pulse} 2s infinite ease-in-out, ${pixelate} 2s linear forwards;
  z-index: 1;

  @media (min-width: 1960px) {
    font-size: 130px; 
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ progress }) => `rgba(${255 - progress * 2.55}, ${255 - progress * 2.55}, ${255 - progress * 2.55}, 1)`};
  transition: background-color 1s linear;
`;

const Counter = styled.div`
  position: absolute;
  top: 60%;  
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  font-weight: 600;
  color: ${({ progress }) => `rgb(${progress * 2.55}, ${progress * 2.55}, ${progress * 2.55})`};
  transition: color 1s linear;

  @media (min-width: 1960px) {
    font-size: 38px; 
  }
`;

const Loading = () => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress(oldProgress => {
                if (oldProgress === 100) {
                    clearInterval(interval);
                    return 100;
                }
                return Math.min(oldProgress + 1, 100);
            });
        }, 20); 

        return () => {
            clearInterval(interval);
        };
    }, []);


    return (
        <LoadingContainer progress={progress}>
            <CameraIcon className="bi bi-camera-reels-fill" progress={progress} />
            <Counter progress={progress}>{progress}%</Counter>
        </LoadingContainer>
    );
};
 
export default Loading;