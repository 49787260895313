import styled from "styled-components";
import VideoComponent from "../../UI/video/VideoComponent";
import { useState, useEffect, useRef } from "react";
import contentfulClient from '../../../contentfulClient';
import VideoModal from "../../UI/videoModal/VideoModal";
import Typewriter from "../../UI/typewriter/TypeWriter";

const StyledPortfolio = styled.section`
    background-color: #ffffff;
    color: #000000;
    position: relative;
    transition: background-color 1s ease; 
    padding-bottom: 50px;

    > div > h1 {
        animation: fadeIn 1s ease-in-out;
        margin: 10px 0 0 0;
        padding: 30px;
        font-size: clamp(2.5rem, 4vw, 3.5rem);
        transition: color 0.5s ease;

        @media (max-width: 768px) {
            text-align: center;
        }
    }

    @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
    }

    .portfolio {
        display: flex;
        justify-content: center;
    }

    @media (min-width: 1920px) {
        .portfolio > h1 {
            font-size: 5rem;
        }
    }

`

const FilterButton = styled.button`
  background-color: ${props => props.isActive ? '#158680' : '#ffffff'};
  color: ${props => props.isActive ? 'white' : 'black'};
  font-weight: 800;
  font-size: 14px;
  border: none;
  padding: 15px 30px;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  @media (min-width: 1920px) {
    font-size: 18px;
  }

  &:hover {
    background-color: ${props => props.isActive ? '#158680' : '#158680'}; 
    color: ${props => props.isActive ? 'white' : 'white'};
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  }

  &:focus {
    outline: none;
  }

  @media (max-width: 768px) {
    font-size: 14px; 
    padding: 8px 12px; 
  }
`;

const FilterButtonContainer = styled.div`
  display: flex;
  justify-content: center; 
  flex-wrap: wrap; 
  margin: 20px 0; 

  @media (max-width: 768px) {
    flex-direction: row; 
    margin: 0;
  }
`;

const StyledButton = styled.button`
  border: 2px solid black;
  padding: 14px 20px;
  background-color: white;
  color: black;
  font-weight: 800;
  display: block; 
  margin: 20px auto; 

  &:hover {
    background-color: #158680; 
    color: white; 
    cursor: pointer; 
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    border: 2px solid #158680;
  }
`;



const Portfolio = () => {
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [videos, setVideos] = useState([]);
    const [filter, setFilter] = useState('All');
    const [showAllVideos, setShowAllVideos] = useState(false);
    const portfolioRef = useRef(null);
    const [isExiting, setIsExiting] = useState(false);
    
    useEffect(() => {
        setShowAllVideos(false);
    }, []);

    useEffect(() => {
        contentfulClient.getEntries({
            content_type: 'videos',
        })
        .then((response) => {
            const fetchedVideos = response.items
                .map((item) => ({
                    id: item.sys.id,
                    title: item.fields.title,
                    description: item.fields.description,
                    videoUrl: item.fields.videoUrl,
                    thumbnailUrl: item.fields.thumbnailImage.fields.file.url,
                    datePublished: item.fields.datePublished,
                    category: item.fields.category,
                }))
                .sort((a, b) => new Date(b.datePublished) - new Date(a.datePublished));
    
            setVideos(fetchedVideos);
        })
        .catch(console.error);
    }, []);

    const filters = ['All', 'Commercial', 'Events', 'Product Commercial', 'Personal Events', 'Music Videos']; 

        const FilterComponent = () => (
            <FilterButtonContainer>
                {filters.map(f => (
                    <FilterButton
                      key={f}
                      onClick={() => setFilter(f)}
                      isActive={filter === f} 
                    >
                        {f}
                    </FilterButton>
                ))}
            </FilterButtonContainer>
        );

    let filteredVideos = videos.filter(video => filter === 'All' || video.category === filter);

    if (!showAllVideos) {
        filteredVideos = filteredVideos.slice(0, 10);
    }
    
    const handleVideoClick = (videoUrl) => {
        setSelectedVideo(videoUrl);
    };

    const handleCloseModal = () => {
        setSelectedVideo(null);
    };

    const handleShowMore = () => {
        setShowAllVideos(true);
    };

    const handleShowLess = () => {
        const scrollDuration = 1000;
    
        portfolioRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    
        setTimeout(() => {
            setShowAllVideos(false);
        }, scrollDuration);
    };

    return ( 
        <StyledPortfolio ref={portfolioRef} className="styled-portfolio" id="profileSection">
            <div className="portfolio">
                <h1><Typewriter text="Projects Collection" typingSpeed={75} /></h1>
            </div>
            <div className="filter-components">
                <FilterComponent />
            </div>
            <div className="video-holder">
                <VideoComponent
                videoPaths={filteredVideos}
                onVideoClick={handleVideoClick}
                isExiting={isExiting}
                controls/>
            </div>
            <VideoModal videoUrl={selectedVideo} onClose={handleCloseModal}/>
            {filter === 'All' && !showAllVideos && (
                <StyledButton onClick={handleShowMore}>Show More</StyledButton>
            )}
            {filter === 'All' && showAllVideos && (
                <StyledButton onClick={handleShowLess}>Show Less</StyledButton>
            )}
        </StyledPortfolio>
     );
}
 
export default Portfolio;