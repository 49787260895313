import styled from "styled-components";

const PlayButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 4rem; 
  color: white; 
  display: block; 

  
  @media (min-width: 768px) {
    display: none;

    &:hover {
      display: block;
    }
  }
`;

const Thumbnail = styled.div`
  position: relative;
  display: inline-block; 
  width: auto;
  height: auto; 

  img {
    display: block; 
    width: 100%; 
    height: auto; 
  }

  &:hover ${PlayButton} {
    display: block; 
  }
`;


const VideoThumbnail = ({ thumbnailUrl }) => {
  return ( 
    <Thumbnail>
      <img src={thumbnailUrl} alt="Video Thumbnail" />
      <PlayButton className="bi bi-play-circle" />
    </Thumbnail>
  );
};

export default VideoThumbnail;