import styled from 'styled-components'

const StyledModal = styled.div`

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.893);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;  

    .videoContainer {
        width: 90%; 
        max-width: 1100px;
        aspect-ratio: 16 / 9; 
    }

    .videoContainer iframe {
        width: 100%; 
        height: 100%; 
        border: none;
    }

    @media (max-width: 768px) {
        .videoContainer {
            width: 95%; 
        }
    }

    @media (min-width: 1920px) {
        .videoContainer {
        width: 90%; 
        max-width: none;
        aspect-ratio: 16 / 9; 
        }
    }
`

const VhsVideo = ({onClose}) => {

    const youtubeVideoUrl = "https://www.youtube.com/embed/5mRUjfEfCng?autoplay=1";

    return ( 
        <StyledModal onClick={onClose}>
            <div className="videoContainer">
                <iframe 
                        src={youtubeVideoUrl} 
                        title="YouTube video" 
                        allow="autoplay" 
                        allowFullScreen
                ></iframe>
            </div>
        </StyledModal>
     );
}
 
export default VhsVideo;