import styled, { keyframes } from "styled-components";
import logo1 from "../../images/AKA-Baltic-juodas.png";
import logo2 from "../../images/ant logas juodas.png";
import logo3 from "../../images/BoSafety_logo_juodas.png";
import logo4 from "../../images/finejas-juodas.png";
import logo5 from "../../images/galiu-juodas.png";
import logo6 from "../../images/KSU_LT_juodas-01.png";
import logo7 from "../../images/Nostra_juodas.png";
import logo8 from "../../images/Skinija logo 2021 02 09-03.png";
import logo9 from "../../images/TAURO_PRO-LINE_LOGO2021_black-01 (1).png";
import logo10 from "../../images/ubr-logo-black.png";
import logo11 from "../../images/VDU_logo_horizontalus_juodas_LT_RGB.png";

const scroll = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
`;

const StyledClients = styled.section`
  h3 {
    padding-left: 50px;
    margin-bottom: 50px;
  }

  @media (max-width: 768px) {
    h3 {
        padding-left: 20px;
    }
  }
`;

const LogosContainer = styled.div`
  overflow: hidden;
  width: 100%; 
  margin-bottom: 40px;
`;

const LogosWrapper = styled.div`
  display: flex;
  gap: 100px;
  animation: ${scroll} 60s linear infinite;
  width: fit-content; 
  &:hover {
    animation-play-state: paused;
  }
`;

const Logo = styled.img`
  flex: 0 0 auto;
  max-width: 160px; 
  max-height: 100px; 
  margin-right: 20px; 
  object-fit: contain; 
`;

const ClientsLogo = () => {
  return (
    <LogosContainer>
      <LogosWrapper>
        <Logo src={logo1} alt="Logo 1" />
        <Logo src={logo2} alt="Logo 2" />
        <Logo src={logo3} alt="Logo 3" />
        <Logo src={logo4} alt="Logo 4" />
        <Logo src={logo5} alt="Logo 5" />
        <Logo src={logo6} alt="Logo 6" />
        <Logo src={logo7} alt="Logo 7" />
        <Logo src={logo8} alt="Logo 8" />
        <Logo src={logo9} alt="Logo 9" />
        <Logo src={logo10} alt="Logo 10" />
        <Logo src={logo11} alt="Logo 11" />
        {/* Repeat logos to create seamless loop */}
        <Logo src={logo1} alt="Logo 1" />
        <Logo src={logo2} alt="Logo 2" />
        <Logo src={logo3} alt="Logo 3" />
        <Logo src={logo4} alt="Logo 4" />
        <Logo src={logo5} alt="Logo 5" />
        <Logo src={logo6} alt="Logo 6" />
        <Logo src={logo7} alt="Logo 7" />
        <Logo src={logo8} alt="Logo 8" />
        <Logo src={logo9} alt="Logo 9" />
        <Logo src={logo10} alt="Logo 10" />
        <Logo src={logo11} alt="Logo 11" />
      </LogosWrapper>
    </LogosContainer>
  );
};

const Clients = () => {
  return (
    <StyledClients>
      <h3>Satisfied clients:</h3>
      <ClientsLogo />
    </StyledClients>
  );
};

export default Clients;